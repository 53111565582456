// Defined the hieght of each ag-grid
// According to the container
.ag-grid-container {
  @include calc-dynamic(height, "100vh - 170px");
  &.xmm-publish-history-grid {
    @include calc-dynamic(height, "100vh - 110px");
  }
  &.xmm-filters {
    @include calc-dynamic(height, "100vh - 220px");
    .ag-root {
      border-top: 0;
    }
    .ag-header-row:first-child .ag-header-group-cell {
      border-top-width: 1px;
    }
    &.banner-enabled {
      @include calc-dynamic(height, "100vh - 295px");
    }
  }
  &.xmm-packages {
    @include calc-dynamic(height, "100vh - 175px");
  }
  &.xmm-overrides {
    @include calc-dynamic(height, "100vh - 340px");
  }

  .fas,
  .far {
    margin: 0 8px;
    font-size: 14px;
  }
  .fa-exclamation-circle {
    color: $Ferrari500;
  }
  .fa-check {
    color: $Krypton500;
  }
  .fa-question-circle,
  .fa-minus {
    color: $Titanium600;
  }
  .fa-copy {
    color: $Cobalt500;
    cursor: pointer;
    margin-right: 0;
  }
  .fa-pen {
    color: $Titanium500;
    margin: 4px;
  }
}
.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  margin-top: 0;
  height: 32px;
}
.ag-theme-balham .ag-header-group-cell.header-hidden {
  background-color: $Titanium200;
  color: transparent;
  border-top-width: 0 !important;
}
.ag-theme-balham .ag-header-cell.white-bg,
.ag-theme-balham .ag-cell.white-bg {
  background-color: $white;
}
.ag-theme-balham .ag-header-cell.no-separator::after,
.ag-theme-balham .ag-cell.no-separator::after {
  height: 0;
}
.ag-grid-storehours-container {
  height: 280px;
  margin-top: 12px;
  .xmm-weekdays-select {
    width: 220px;
  }
  .ag-theme-balham .ag-rich-select .ag-rich-select-list {
    width: 100px;
  }
}
.ag-theme-balham .ag-ltr .ag-cell.editable-cell.ag-column-hover:hover {
  cursor: pointer;
  border: 1px solid $Cobalt500;
}
.ag-theme-balham .ag-ltr .ag-cell.editable-cell.ag-cell-inline-editing {
  border: 1px solid $Cobalt500;
}
.ag-theme-balham
  .ag-ltr
  .ag-cell.editable-cell.non-empty:empty.ag-column-hover:hover {
  cursor: default;
  border: none;
  border-right: 1px solid $Titanium400;
}
.ag-theme-balham .ag-ltr .ag-cell.editable-caret-cell.ag-column-hover:hover {
  cursor: pointer;
  border: 1px solid $Cobalt500;
  padding-right: 20px;
  box-sizing: border-box;
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $Cobalt500;
    position: absolute;
    right: 8px;
    top: 10px;
  }
}
.ag-theme-balham .ag-input-wrapper input:not([type]):disabled,
.ag-theme-balham .ag-input-wrapper input[type="text"]:disabled,
.ag-theme-balham .ag-input-wrapper input[type="number"]:disabled,
.ag-theme-balham .ag-input-wrapper input[type="tel"]:disabled,
.ag-theme-balham .ag-input-wrapper input[type="date"]:disabled,
.ag-theme-balham .ag-input-wrapper input[type="datetime-local"]:disabled {
  cursor: not-allowed;
}
.ag-theme-balham .ag-ltr .ag-cell.editable-disabled-cell.ag-column-hover:hover {
  cursor: not-allowed;
}
.xmm-select-operations-container {
  height: 450px;
  width: 100%;
  margin: 12px 0 4px 0;
}
.xmm-show-inherited-rules-container {
  height: 650px;
  width: 100%;
  margin: 12px 0 4px 0;
}
.xmm-find-opcode-container {
  height: 380px;
  width: 100%;
  margin: 12px 0 4px 0;
}
.xmm-publish-grid-container {
  height: 250px;
  width: 100%;
  margin: 12px 0 4px 0;
}
.xmm-mileage-container {
  height: 380px;
  width: 100%;
  margin-bottom: 12px;
}
.ag-grid-overlay-scroll .ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}
.ag-theme-balham .ag-cell.xmm-link-cell {
  white-space: normal;
  line-height: 16px;
  color: $Cobalt500;
  cursor: pointer;
  padding: 4px 12px;
  &:hover {
    text-decoration: underline;
  }
}
.ag-theme-balham .ag-group-component .ag-group-component-title-bar {
  background-color: $Titanium400;
}
.ag-theme-balham .ag-cell.xmm-blue-cell {
  white-space: normal;
  line-height: 16px;
  color: $Cobalt500;
  cursor: pointer;
  width: 100%;
  padding: 4px 12px;
  &.ag-cell-inline-editing {
    padding: 0;
  }
}
.ag-theme-balham .ag-cell.xmm-wrap-cell {
  white-space: normal;
  line-height: 16px;
  padding: 4px 12px;
}
.ag-theme-balham .ag-cell-inline-editing select {
  padding: 4px 12px;
  background-color: $Titanium300;
  border-color: $Titanium600;
}

/* Checkboxes CX styles*/
.ag-theme-balham .ag-icon-checkbox-unchecked {
  background: $white;
  border: 1px solid $Titanium600;
  @include border-radius(4px);
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: block;
  &::before {
    content: "";
  }
}
.ag-theme-balham .ag-icon-checkbox-checked {
  background: $Cobalt500;
  @include border-radius(4px);
  cursor: pointer;
  &:empty {
    background-image: none;
    position: relative;
  }
  &::after {
    content: "";
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
  }
}
.ag-theme-balham .ag-selection-checkbox span {
  position: inherit;
}
.ag-theme-balham .ag-icon-checkbox-indeterminate {
  background: $Cobalt500;
  @include border-radius(4px);
  cursor: pointer;
  position: relative;
  &::after {
    content: "";
    left: 4px;
    top: -1px;
    width: 8px;
    height: 10px;
    border-bottom: 2px solid $white;
    position: absolute;
  }
}
.ag-theme-balham .ag-overlay-loading-center {
  border: 0;
}

//Ag-Grid cells tooltip
.xmm-custom-tooltip {
  position: absolute;
  width: 300px;
  height: auto;
  border: none;
  overflow: inherit;
  font-size: 12px;
  transition: opacity 1s;
  background-color: $Cobalt800;
  color: $white;
  @include border-radius(4px);
}

.xmm-custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.xmm-custom-tooltip p {
  margin: 4px;
  word-break: break-word;
}
.xmm-group-cell {
  padding: 0 !important;
  background: $Titanium500;
  width: 1px !important;
}
.ag-theme-balham .ag-header-icon .ag-icon-filter:before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f0b0";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  top: -4px;
  color: $Cobalt500;
}
.xmm-flex-cell {
  display: flex;
  justify-content: space-between;
  .fa-check {
    margin-left: 0;
    align-self: center;
  }
}

.ag-theme-balham .ag-cell-data-changed {
  background-color: transparentize($Krypton500, 0.5) !important;
}
.ag-theme-balham .ag-cell-data-changed-animation{
  background-color: transparent;
  transition: background-color 5s !important;
}
